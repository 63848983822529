// router.js

import { createRouter, createWebHistory } from 'vue-router';
// import App from "../App.vue";
import HorseGuide from "../pages/at-yarisi-rehberi/HorseGuide.vue"
import HorseGuideDetail from "../pages/at-yarisi-rehberi/HorseGuideDetail.vue";
import LivePage from "../pages/LivePage.vue"
// import ErrorPage from "../layouts/ErrorPage.vue";

const routes = [
  { path: '/', component: LivePage},
  {path:"/at-yarisi-rehberi",component:HorseGuide},
  {path:"/at-yarisi-rehberi/:slug",component:HorseGuideDetail},
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
