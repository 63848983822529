import { createApp } from 'vue'
import { createHead,VueHeadMixin } from '@unhead/vue'
import App from "./App.vue";
import routes from "./routes/routes";
import './assets/tailwind.css'
const app = createApp(App)
const head = createHead()
app.use(head)
app.use(routes);
app.mixin(VueHeadMixin);
app.mount('#app')

