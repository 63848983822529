<template>
  <section class="container md:max-w-[552px] lg:max-w-full lg:pt-10 md:pt-[90px] md:px-0 md:pb-0 p-[40px] w-full my-2.5">
    <div class="text-black text-3xl font-medium leading-loose">Sıkça Sorulan Sorular</div>
    <div v-for="(faq, i) in faqList" :key="i" :class="i !== (faqList.length - 1) && 'border border-b-zinc-300'"
         class="flex flex-col gap-[24px] py-[20px] rounded-md">
      <div class="flex items-center justify-between cursor-pointer text-black text-base font-bold leading-tight">
        <div class="w-full" @click="toggleActiveFaq(i)">{{ faq.title }}</div>
        <div class="flex gap-2 items-center">
          <i @click="toggleActiveFaq(i)" class="transition-all"
             :class="activeFaq === i ? 'rotate-0' : 'transform rotate-180'">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 9L7.5 13.5H16.5L12 9Z" fill="black"/>
            </svg>
          </i>
          <!-- <span @click="toggleActiveFaq(null)" v-if="activeFaq === i"
                class="text-right text-black text-sm font-bold leading-tight">Kapat</span> -->
        </div>
      </div>
      <div :class="activeFaq === i ? ' absolute top-20 h-0 opacity-0 invisible' : 'relative h-full opacity-1 visible'"
           class="visible transition-all text-neutral-500 text-sm font-medium leading-tight" v-html="faq.description">
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue';
import FAQList from '../data/faqData.json';

const activeFaq = ref(null);
const faqList = ref(FAQList);

const toggleActiveFaq = (index) => {
  activeFaq.value = activeFaq.value === index ? null : index;
};
</script>
