<template>
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQ9BNCL5" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <div class="bg-gray-200">
    <header :class="isPage && '!bg-black'"
            class="flex justify-center flex-col items-center w-full h-[120px] md:h-[100px] bg-white">
      <div class="container md:px-[35px] flex items-center justify-between w-full max-w-[1104px] md:gap-20">
        <router-link :to="{ path: '/' }">
        <img src="/images/logo.png" width="99" height="85" alt="">
        </router-link>
        <div class="hidden md:flex flex-1 gap-4 h-full items-center">
          <div :class="{ 'purple-underline': $route.path === '/', 'flex items-center h-full justify-center': $route.path === '/' }">
            <router-link 
              to="/" 
              :class="{ 'font-bold': $route.path === '/', 'text-white': $route.path.includes('at-yarisi-rehberi') }" 
              class="text-black text-sm leading-tight"
            >
              CANLI YAYIN
            </router-link>
          </div>
          <div :class="{ 'purple-underline': $route.path.includes('at-yarisi-rehberi'), 'flex items-center h-full justify-center': true }">
        <router-link 
          :to="{ path: '/at-yarisi-rehberi' }" 
          :class="{ 'font-bold': $route.path === '/at-yarisi-rehberi', 'text-white': $route.path.includes('at-yarisi-rehberi') }" 
          class="text-black text-sm leading-tight"
        >
          AT YARIŞI REHBERİ 
        </router-link>
      </div>
        </div>
        <!-- MOBİL -->
        <div>
          <time class="flex gap-2 text-sm justify-end" :class="$route?.path == '/' ? 'text-black' : 'text-neutral-500'">
          <span class="opacity-70">{{ date }}</span>
          <span class="font-bold">{{ currentTime }}</span></time>
          <div class="flex md:hidden mt-4 gap-1 w-full items-end">
              <div class="whitespace-nowrap"  :class="$route?.path == '/' && 'purple-underline flex items-center h-full justify-center'">
                <router-link :to="{ path: '/' }" :class="{ 'font-bold': $route.path === '/', 'text-white': $route.path.includes('at-yarisi-rehberi') }" class="text-black text-sm leading-tight">CANLI YAYIN</router-link>
              </div>
              <div class="whitespace-nowrap" :class="$route?.path.includes('at-yarisi-rehberi') == true && 'purple-underline flex items-center h-full justify-center'">
                <router-link :to="{ path: '/at-yarisi-rehberi' }" :class="{ 'font-bold': $route.path === '/at-yarisi-rehberi', 'text-white': $route.path.includes('at-yarisi-rehberi') }" class="text-black text-sm leading-tight">AT YARIŞI REHBERİ</router-link>
              </div>
          </div>
        </div>
      </div>
    </header>
    <main id="main" class="w-full">
      <router-view></router-view>
    </main>
    <footer class="flex items-center justify-center w-full h-[140px] md:h-[184px] bg-neutral-900">
      <div
          class="container flex items-center lg:justify-between w-full max-w-[1104px] gap-[15px] md:gap-[28px] lg:gap-20 max-lg:flex-col">
        <div class="max-lg:order-2 text-neutral-500 text-base font-bold">© 2024 Copyrighted by Tay TV</div>
        <router-link to="/" class="max-lg:order-1">
          <img src="/images/footer-logo.png" width="113" height="38" alt="">
        </router-link>
      </div>
    </footer>
  </div>
</template>
<script>
  import { ref, onMounted, watch } from 'vue';
  import { useRoute } from 'vue-router';
  export default {
    name:"App",
    head(){
      return {
        title: 'Tay Tv',
        description: 'Tay Tv',
        activeIndex:'live',
        meta: [
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1"
          },
          {
            charset: "UTF-8"
          },
          {
            name: "keywords",
            content: 'at yarışı, at yarışı izle, at yarışı canlı tv, at yarışı canlı izle, at yarışı canlı yayın, at yarışı izle canlı, at yarışı canlı, canlı yayın at yarışı, at yarışı izle sonuçları, tjk, tjk canlı, tjk canlı yayın, tjk canlı sonuçlar, tjk canlı izle, tay tv canlı yayın, tay tv canlı, tay tv, taytv, tay tv canlı yayın izle',
          },
        ],
        script: [
          {
            children: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-PQ9BNCL5');
                `,
            type: 'text/javascript'
          }
        ]
      }
    },
    setup() {
    const route = useRoute();
    const isPage = ref(route?.path.includes("at-yarisi-rehberi"));
    const date = ref(new Date());
    date.value = date.value.toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const currentTime = ref('');
    const activeMenu = ref('');

    const getCurrentTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    };
    const updateCurrentTime = () => {
      currentTime.value = getCurrentTime();
    };

    onMounted(() => {
      updateCurrentTime();
      setInterval(updateCurrentTime, 1000);
    });

    watch(() => route.path, () => {
      isPage.value = route?.path.includes("at-yarisi-rehberi");
    }, { immediate: true });

    return { isPage, date, currentTime, activeMenu };
  }
}
</script>
<style scoped>
  .purple-underline{border-bottom:3px solid #7320DD;flex-basis:160px}
</style>

