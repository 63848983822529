<template>
  <div class="bg-black pt-[15px] pb-10 md:pb-[160px]">
    <div class="container">
      <button @click="goBack"
              class="max-lg:hidden text-white text-base font-bold leading-normal text-white text-base font-bold leading-normal flex items-center gap-[10px] opacity-50 hover:opacity-100 transition-all">
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M5.98524 0.0147662L7.42337 1.4529L3.9318 4.94446L14.9825 4.98248L14.9895 7.01055L3.8287 6.97215L7.34436 10.4878L5.91609 11.9161L9.48178e-07 6L5.98524 0.0147662Z"
              fill="white"/>
        </svg>
        Geri Dön
      </button>
      <h1 class="p-0 md:px-[92px] text-center text-white text-3xl font-medium leading-snug">{{ response?.title }}</h1>
    </div>
  </div>
  <div class="container lg:max-w-4xl max-md:px-0">
    <article id="article" class="bg-white relative md:top-[-119px] p-0 py-[69px] md:px-[92px]">
      <div class="max-md:px-10 max-md:pb-10" v-if="response?.blogDetail?.content?.section1">
        <p class="text-neutral-500 text-sm font-medium mb-11 md:mb-[72px] leading-5"
           v-html="response?.shortDesc"/>
      </div>
      <img :src="`${response?.image}`" :alt="response?.title" class="max-w-full mb-[60px]">
      <div class="max-md:px-10 max-md:pb-10" v-for="content,index in response?.blogDetail?.content" :key="'content'+index">
        <h2 class="text-black text-3xl font-bold mb-6 md:mb-[33px] italic">
          {{ content.title }}</h2>
        <p class="text-neutral-500 text-sm font-medium mb-11 md:mb-[72px] leading-5"
           v-html="getContent(content)"/>
      </div>
      <div class="max-md:px-10" v-if="response?.blogDetail?.lists"> <!-- TODO: Component yapılabilir. ui/blog/list.vue -->
        <H3 class="text-black text-base font-bold leading-normal mb-9">{{ response?.blogDetail?.lists?.title }}</H3>
        <ul class="list-disc flex flex-col gap-7">
          <li v-for="item,index in response?.blogDetail?.lists?.content" class="text-red-600 text-sm font-bold leading-tight" :key="'subContent'+index">
            <p class="text-black">{{item}}</p>
          </li>
        </ul>
      </div>
    </article>
  </div>
  <div v-if="beforeBlog || afterBlog" class="container lg:max-w-4xl max-md:px-0 mb-40"> <!-- TODO: Componenet yapılabilir ui/blog/other-blog.vue -->
    <div class="font-medium text-center mx-auto mb-9 lg:max-w-md max-md:px-[45px] max-md:pt-16 max-md:pb-9">
      <h3 class="text-black text-3xl leading-loose">Diğer Yazılar</h3>
      <p class="text-neutral-500 text-sm leading-tight">At yarışı severler için hem bir rehber niteliğinde hem de bu
        sporun nabzını tutan güncel haberleri derledik.</p>
    </div>
    <div class="flex md:gap-3 flex-nowrap overflow-x-scroll scrolling-touch items-start snap-x snap-mandatory">
      <div v-if="beforeBlog" class="max-md:min-w-[100vw] md:w-1/2 bg-white py-[55px] md:py-[50px] px-[45px] md:px-[93px]">
        <h4 class="line-clamp-2 text-black text-base font-bold leading-normal mb-3.5 italic">{{beforeBlog.title}}</h4>
        <div class="text-neutral-500 text-sm font-medium leading-5 mb-3.5 line-clamp-5" v-html="beforeBlog.shortDesc"></div>
        <router-link :to="'/at-yarisi-rehberi/'+beforeBlog.slug"
                   class="italic text-black text-base font-bold leading-normal inline-flex items-center gap-[13px] hover:text-red-600 transition-all">
          devamını oku
          <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"
               data-v-inspector="pages/blog/index.vue:43:17">
            <path
                d="M9.01476 0.0147662L7.57663 1.4529L11.0682 4.94446L0.0175255 4.98248L0.0105475 7.01055L11.1713 6.97215L7.65564 10.4878L9.08391 11.9161L15 6L9.01476 0.0147662Z"
                fill="black"></path>
          </svg>
        </router-link>
      </div>
      <div v-if="afterBlog" class="max-md:min-w-[100vw] md:w-1/2 bg-white py-[55px] md:py-[50px] px-[45px] md:px-[93px]">
        <h4 class="line-clamp-2 text-black text-base font-bold leading-normal mb-3.5 italic">{{afterBlog.title}}</h4>
        <div class="text-neutral-500 text-sm font-medium leading-5 mb-3.5 line-clamp-5" v-html="afterBlog.shortDesc"></div>
        <router-link :to="'/at-yarisi-rehberi/'+afterBlog.slug"
                   class="italic text-black text-base font-bold leading-normal inline-flex items-center gap-[13px] hover:text-red-600 transition-all">
          devamını oku
          <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"
               data-v-inspector="pages/blog/index.vue:43:17">
            <path
                d="M9.01476 0.0147662L7.57663 1.4529L11.0682 4.94446L0.0175255 4.98248L0.0105475 7.01055L11.1713 6.97215L7.65564 10.4878L9.08391 11.9161L15 6L9.01476 0.0147662Z"
                fill="black"></path>
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted,ref,watch} from "vue";
import { useRoute,useRouter } from 'vue-router';

const route = useRoute()
const router = useRouter();
import data from '../../data/blogData.json';

const response = ref({})
const beforeBlog = ref({})
const afterBlog = ref({})
function goBack(){
  router.back();
  console.log("goback")
  window.scrollTo(0,0);
}
response.value = data.blogList.find(blog => blog.slug == route.params.slug);
watch(() => route.path, () => {
    response.value = data.blogList.find(blog => blog.slug == route.params.slug);
    window.scrollTo(0,0);
    }, { immediate: true });
onMounted(() => {
  let findIndex = data.blogList.findIndex(blog => blog.slug == route.params.slug);
  let beforeIndex=findIndex - 1, afterIndex=findIndex + 1;

  let dataLength = data.blogList.length - 1;
  if (findIndex == dataLength) {
    beforeIndex = dataLength - 1;
    afterIndex = 0
  }
  if (findIndex == 0){
    beforeIndex = dataLength;
    afterIndex = findIndex + 1
  }
  beforeBlog.value = data.blogList.find((item,index) => index == beforeIndex && findIndex !== index)
  afterBlog.value = data.blogList.find((item,index) => index == afterIndex && index !== beforeIndex)
    document.title = response.value?.title + ' - Tay Tv';
    // document.querySelector('meta[name="description"]').setAttribute('content',response.value?.shortDesc);
    // document.querySelector('meta[property="og:title"]').setAttribute('content', response.value?.title + ' - Tay Tv');
    // document.querySelector('meta[property="og:description"]').setAttribute('content', response.value?.shortDesc);
    // document.querySelector('meta[property="og:image"]').setAttribute('content', window.location.origin + response.value?.image);
    // document.querySelector('meta[name="twitter:card"]').setAttribute('content', 'summary_large_image');
})
function getContent(content){
  return content.description.replace('{{image}}',`<img src='${content.image}' alt=''/>`).replace('{{banner}}',`<a target="_blank" href="${content.bannerUrl}" title="${content.bannerTitle}" class="mb-16 flex">
        <img src="${content.banner}" alt="${content.bannerTitle}"
             class="max-w-full hover:scale-125 ease-in duration-500">
      </a>`)
}
</script>
<style scoped>

</style>