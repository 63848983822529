<template>
  <div class="bg-black pt-[15px] pb-[160px]">
    <div class="container">
      <div class="text-center">
        <h1 class="text-white text-3xl font-medium leading-loose"> At Yarışı Rehberi </h1>
        <p class="text-center text-zinc-300 text-sm font-medium leading-5">At yarışı severler için hem bir rehber
          niteliğinde hem de bu sporun nabzını<br/> tutan güncel haberleri derledik.</p>
      </div>
    </div>
  </div>
  <div class="relative top-[-119px]">
    <div class="container max-md:px-0">
      <div class="relative mb-3 md:mb-[58px]">
        <img class="w-full h-auto" :src="`${heroBlog?.image}`" :alt="heroBlog.title">
        <div
            class="absolute left-0 bottom-0 bg-white z-10 md:px-24 md:py-12 pt-[27px] pb-[18px] px-11 flex flex-col items-end gap-0.5">
          <h2 class="text-black text-base font-bold leading-normal italic md:max-w-sm line-clamp-3 md:line-clamp-2">{{heroBlog?.title}}</h2>
          <router-link :to="'at-yarisi-rehberi/' + heroBlog?.slug"
                     class="hover:text-red-600 hover:!fill-red-600 transition-all italic text-black text-base font-bold leading-normal inline-flex items-center gap-[13px]">
            devamını oku
            <svg width="15" height="12" viewBox="0 0 15 12" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M9.01476 0.0147662L7.57663 1.4529L11.0682 4.94446L0.0175255 4.98248L0.0105475 7.01055L11.1713 6.97215L7.65564 10.4878L9.08391 11.9161L15 6L9.01476 0.0147662Z"
                  fill="black"/>
            </svg>
          </router-link>
        </div>
      </div>
      <div class="mb-10 md:mb-[58px]">
        <a href="https://www.hipodrom.com/" target="_blank">
          <img src="/images/blog-banner.png" alt="tjk" class="hover:scale-105 ease-in duration-500"/>
        </a>
      </div>
      <div class="md:px-[90px]">
        <div v-for="blog in sectionBlogs" class="relative mb-5 md:mb-[40px] flex gap-[10px]" :key="'sb'+blog.slug">
          <div class="flex-1 flex max-md:hidden">
            <img :src="blog.image" :alt="blog.title" class="w-full h-full object-cover"/>
          </div>
          <div class="bg-white py-14 md:py-[52px] flex-1 md:px-[83px] px-12">
            <h3 class="text-black text-base font-bold leading-normal italic mb-[14px]">
              {{ blog.title }}
            </h3>
            <p class="text-neutral-500 text-sm font-medium leading-tight mb-[10px] line-clamp-5">
              {{ blog.shortDesc }}
            </p>
            <router-link :to="'at-yarisi-rehberi/' + blog.slug"
                       class="hover:text-red-600 transition-all italic text-black text-base font-bold leading-normal inline-flex items-center gap-[13px]">
              devamını oku
              <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.01476 0.0147662L7.57663 1.4529L11.0682 4.94446L0.0175255 4.98248L0.0105475 7.01055L11.1713 6.97215L7.65564 10.4878L9.08391 11.9161L15 6L9.01476 0.0147662Z"
                    fill="black"/>
              </svg>
            </router-link>
          </div>
        </div>
        <div class="w-full lg:max-w-full mb-10 relative">
          <div class="flex bg-white relative lg:pl-[45px] md:pl-[23px] lg:pr-[105px] md:pr-[15px] overflow-hidden">
            <div
                class="flex flex-1 gap-[26px] py-[45px] items-center max-md:flex-col max-md:items-start max-md:pl-[44px] max-md:z-20">
              <a href="https://indir.hipodrom.com/" target="_blank">
                <img src="/images/hipodrome-logo.svg" alt="" width="77" height="77">
              </a>
              <div class="flex flex-col gap-[19px] flex-1">
                <div class="text-black lg:text-3xl text-[22px] font-medium leading-normal max-md:max-w-[78%]">At yarışı
                  heyecanı cebinde!
                </div>
                <div class="flex gap-2.5">
                  <a href="https://apps.apple.com/tr/app/hipodrom-tjk-at-yar%C4%B1%C5%9F%C4%B1/id6472811853?l=tr" target="_blank"
                             class="max-w-[120px] h-[36px] md:h-[48px] px-[9px] md:p-3 bg-gray-200 rounded-full border border-zinc-300 justify-center items-center gap-1 inline-flex">
                    <img width="24" height="24" src="/images/apple-fill.svg" alt="" class="max-md:w-[18px] max-md:h-[18px]">
                    <div class="text-[10px] md:text-[14px] text-black text-sm font-medium leading-tight">App Store</div>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.hipodrom.android" target="_blank"
                             class="max-w-[140px] h-[36px] md:h-[48px] px-[9px] md:p-3 bg-gray-200 rounded-full border border-zinc-300 justify-center items-center gap-1 inline-flex">
                    <img width="24" height="24" src="/images/google-play-fill.svg" alt="" class="max-md:w-[18px] max-md:h-[18px]">
                    <div class="text-[10px] md:text-[14px] text-black text-sm font-medium leading-tight">Google Play</div>
                </a>
                </div>
              </div>
            </div>
            <div
                class="flex items-end justify-center lg:mt-0 mt-[28px] max-md:absolute right-0 bottom-0 max-md:h-full max-md:z-10 max-md:right-[-26%]">
              <img src="/images/hipodrome-mobile.png"
                   class="md:max-h-[140px] lg:max-h-[190px] max-md:max-h-full">
            </div>
          </div>
        </div>
      </div>
      <div class="md:px-[90px] md:mt-[40px]">
        <div class="swiper-container overflow-hidden">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="blog in sliderBlogs" :key="blog.slug">
        <div class="relative bg-white flex gap-[10px]">
          <div class="flex-1 flex max-md:hidden">
            <img :src="blog.image" :alt="blog.title" class="w-full h-full object-cover"/>
          </div>
          <div class="bg-white py-14 md:py-[52px] flex-1 md:px-[83px] px-12">
            <h3 class="text-black text-base font-bold leading-normal italic mb-[14px]">
              {{ blog.title }}
            </h3>
            <p class="text-neutral-500 text-sm font-medium leading-tight mb-[10px] line-clamp-5">
              {{ blog.shortDesc }}
            </p>
            <router-link :to="'at-yarisi-rehberi/' + blog.slug"
                         class="hover:text-red-600 transition-all italic text-black text-base font-bold leading-normal inline-flex items-center gap-[13px]">
              devamını oku
              <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.01476 0.0147662L7.57663 1.4529L11.0682 4.94446L0.0175255 4.98248L0.0105475 7.01055L11.1713 6.97215L7.65564 10.4878L9.08391 11.9161L15 6L9.01476 0.0147662Z"
                    fill="black"/>
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
        <div class="w-full mt-[30px]" v-if="sliderBlogs.length > 1">
          <div class="flex items-center justify-between px-[45px] md:justify-end gap-3">
            <ol class="flex gap-2">
              <li :class="swiper?.activeIndex !== index && 'opacity-20'" @click="changeIndex(index)" :key="'blog'+index"
                  v-for="(_,index) in sliderBlogs" class="cursor-pointer italic text-black text-base font-bold leading-normal">
                {{ index + 1 }}
              </li>
            </ol>
            <div class="flex gap-2">
              <button :class="swiper?.activeIndex == 0 && 'opacity-20'" @click="prev">
                <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5.98524 0.0147662L7.42337 1.4529L3.9318 4.94446L14.9825 4.98248L14.9895 7.01055L3.8287 6.97215L7.34436 10.4878L5.91609 11.9161L9.48178e-07 6L5.98524 0.0147662Z"
                      fill="black"/>
                </svg>
              </button>
              <button :class="swiper?.activeIndex == sliderBlogs.length - 1 && 'opacity-20'" @click="next">
                <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9.01476 0.0147662L7.57663 1.4529L11.0682 4.94446L0.0175255 4.98248L0.0105475 7.01055L11.1713 6.97215L7.65564 10.4878L9.08391 11.9161L15 6L9.01476 0.0147662Z"
                      fill="black"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// Import Swiper styles
import 'swiper/css';
import data from '../../data/blogData.json';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';

import 'swiper/css/pagination';
import {ref,onMounted} from "vue";
const swiper = ref(null)
const heroBlog = ref({})
const sectionBlogs = ref([])
const sliderBlogs = ref([])
const activeIndex = ref(0);

function next() {
  swiper.value.slideNext() // should work
}

function prev() {
  swiper.value.slidePrev() // should work
}

function changeIndex(index) {
  swiper.value.slideTo(index);
}
onMounted(() => {
  SwiperCore.use([Pagination, Navigation, Autoplay]);
  swiper.value = new SwiperCore('.swiper-container', {
    slidesPerView: 1,
    loop: false,
    effect: 'creative',
    autoplay: {
      delay: 1000,
      disableOnInteraction: true,
    },
    on: {
      slideChange: () => {
        swiper.value && (swiper.value.activeIndex !== undefined) && (swiper.value.activeIndex !== null) && (activeIndex.value = swiper.value.activeIndex);
      }
    },
    creativeEffect: {
      prev: {
        shadow: false,
        translate: ['-20%', 0, -1],
      },
      next: {
        translate: ['100%', 0, 0],
      },
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
});

heroBlog.value = data.blogList.find(item => item.isBlogHomeHero);
sectionBlogs.value = data.blogList.filter(item => item.isBlogHomeList);
sliderBlogs.value = data.blogList.filter(item => !item.isBlogHomeList && !item.isBlogHomeHero);
</script>
<style scoped>
/* .swiper-slide{visibility:hidden}
.swiper-slide.swiper-slide-active{visibility: visible;} */
</style>