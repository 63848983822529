<script>
import videojs from "video.js";
import 'video.js/dist/video-js.css'
export default {
  name: 'VideoContainer',
  props: {
    msg: String
  },
  data(){
    return{
      player:null
    }
  },
  mounted() {
    // Initialize Video.js instance
    console.log(window.document.getElementById("tayLiveVideo"))
    this.player = videojs('tayLiveVideo');
  },
  beforeUnmount() {
    // Destroy Video.js instance when component is unmounted
    if (this.player && this.$route?.path != "/") {
      console.log(this.player);
      // this.player.pause();
    }
  }
}
</script>

<template>
  <div class="flex md:max-w-[750px] lg:max-w-full w-full gap-2.5 md:mt-2.5">
      <div class="hidden md:block lg:max-w-full md:max-w-[96px]">
        <a href="https://www.hipodrom.com/tjk-at-yarisi/tjk-bahis-yap" target="_blank">
          <img src="/images/banner-columns.png" alt="" width="160" height="600">
        </a>
      </div>
      <div class="flex-1 min-h-[208px]">
        <video preload="none" id="tayLiveVideo" class="w-full h-full video-js vjs-default-skin" muted autoplay controls>
          <source src="https://duhnet.hipodrom.com/S2/HLS_LIVE/mislitaynp/playlist.m3u8" type="application/x-mpegURL">
        </video>
      </div>
      <div class="hidden md:block lg:max-w-full md:max-w-[96px]">
        <a href="https://www.hipodrom.com/tjk-at-yarisi/tjk-bahis-yap" target="_blank">
          <img src="/images/banner-columns.png" alt="" width="160" height="600">
        </a>
      </div>
    </div>
</template>