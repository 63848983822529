<script>
import VideoContainer from "../components/video-container.vue";
import FAQ from "../components/faq-component.vue";
export default{
  name:"LivePage",
  head(){
      return {
        title: 'Tay TV Canlı Yayın İzle - At Yarışı Analizleri | TayTV',
        meta: [
          {name: 'description', content: 'At yarışı canlı yayın izleme, at yarışı stratejileri, TJK bülten ve ganyan bahis analizleri! At yarışlarını canlı yayın ile Tay TV\'de anında takip edin!\n'}
        ],
      } 
  },
  components:{
    VideoContainer,FAQ
  }
}
//TODO: Örnek banner dataLayer push
// const banner = () => {
//   window.dataLayer.push({
//     event: "select_promotion",
//     ecommerce: {
//       creative_name: "Summer Banner",  // banner ismi
//       creative_slot: "slider_1",  // banner pozisyonu
//       promotion_id: "P_12345",  // banner id
//       promotion_name: "Summer Sale"  // kampanya ismi
//     } })
// }
</script>

<template>
  <div class="flex items-center justify-center w-full bg-gray-200">
    <div class="flex flex-col items-center w-full max-w-[1260px]">
      <VideoContainer/>
      <div class="flex flex-col items-center w-full max-w-[1104px]">
    <section class="lg:max-w-full md:max-w-[552px] w-full mb-14 mt-9">
      <a  href="https://www.hipodrom.com/tjk-at-yarisi/tjk-bahis-yap" title="At Yarışı" target="_blank"> <!-- TODO: COMPONENT YAPILABİLİR. ui/blog/banner.vue -->
        <img src="/images/banner-live.png" alt="" class="max-w-full"/>
      </a>
    </section>
    <section class="w-full lg:max-w-full sm:max-w-[552px]">
      <div class="grid sm:justify-center grid-cols-1 lg:grid-cols-2">
        <div class="min-h-[552px] pt-[100px] md:pt-[61px] md:px-[92px] px-[40px] bg-white relative">
          <div class="flex flex-col gap-[26px] md:mb-[-30px]">
            <div class="text-black text-[32px] italic font-bold leading-[2rem]">UZMAN <br> YORUMCULAR</div>
            <div class="w-72 text-neutral-500 text-sm font-medium leading-5">Yarış severler için uzman bakış açısıyla
              hazırlanmış değerlendirmeler ve stratejik ipuçları hipodrom.com'da!
            </div>
            <a href="https://www.hipodrom.com/tjk-at-yarisi/uzman-yorumlari/" target="_blank" class="max-w-[150px] italic bg-black rounded-3xl px-3 py-2 h-10 flex items-center gap-1 text-white text-base font-bold leading-normal z-10">
              <span>hipodrom.com</span>
              <i>
                <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9.01476 0.0147662L7.57663 1.4529L11.0682 4.94446L0.0175255 4.98248L0.0105475 7.01055L11.1713 6.97215L7.65564 10.4878L9.08391 11.9161L15 6L9.01476 0.0147662Z"
                      fill="white"/>
                </svg>
              </i>
            </a>
          </div>
          <div class="max-md:mt-[37px]">
            <img src="/images/yazar-grup@2x.png" alt="" class="max-w-full"/>
          </div>
        </div>
        <div class="md:min-h-[552px] md:pb-0 p-[30px] md:pt-[150px] pt-[75px] flex flex-col md:pl-[93px] pl-[40px] md:gap-[46px] gap-[33px] bg-red-600 relative">
          <div class="text-white text-[32px] font-bold italic leading-[2rem]">DETAYLI<br/>İSTATİSTİKLER</div>
          <div class="w-72 text-white text-sm font-medium leading-tight">At yarışlarına, koşulara, jokeylere,
            antrenörlere ve geçmiş verilere dayanan detaylı istatistikler hipodrom.com'da!
          </div>
          <div class="w-full flex items-center md:justify-start justify-end">
            <a href="https://www.hipodrom.com/" target="_blank"
                       class="max-w-[150px] md:mt-0 mt-[37px] italic bg-black rounded-3xl px-3 py-2 h-10 flex items-center gap-1 text-white text-base font-bold leading-normal">
              <span>hipodrom.com</span>
              <i>
                <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9.01476 0.0147662L7.57663 1.4529L11.0682 4.94446L0.0175255 4.98248L0.0105475 7.01055L11.1713 6.97215L7.65564 10.4878L9.08391 11.9161L15 6L9.01476 0.0147662Z"
                      fill="white"/>
                </svg>
              </i>
            </a>
          </div>
        </div>
        <div class="md:min-h-[552px] md:pb-0 p-[30px] md:pt-[150px] pt-[75px] flex flex-col md:pl-[93px] pl-[40px] md:gap-[46px] gap-[33px] bg-black relative">
          <div class="text-white text-[32px] font-bold italic leading-[2rem]">HAZIR<br/>KUPONLAR</div>
          <div class="w-72 text-zinc-300 text-sm font-medium leading-tight">"Uzman yazarlar tarafından sizler için özenle
            hazırlanmış tahminlerin olduğu hazır kuponlar hipodrom.com'da!"
          </div>
          <a href="https://www.hipodrom.com/tjk-at-yarisi/hazir-kuponlar" target="_blank"
                     class="max-w-[150px] italic bg-red-600 rounded-3xl px-3 py-2 h-10 flex items-center gap-1 text-white text-base font-bold leading-normal">
            <span>hipodrom.com</span>
            <i>
              <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.01476 0.0147662L7.57663 1.4529L11.0682 4.94446L0.0175255 4.98248L0.0105475 7.01055L11.1713 6.97215L7.65564 10.4878L9.08391 11.9161L15 6L9.01476 0.0147662Z"
                    fill="white"/>
              </svg>
            </i>
          </a>
        </div>
        <div class="md:min-h-[552px] md:pb-0 p-[60px] md:pt-[150px] pt-[75px] flex flex-col md:pl-[93px] pl-[40px] md:gap-[46px] gap-[33px] bg-neutral-100 relative">
          <div class="text-black text-[32px] font-bold italic leading-[2rem]">AT YARIŞI<br/>OYNA</div>
          <div class="w-72 text-neutral-500 text-sm font-medium leading-tight">Uzman yorumları, detaylı istatistikleri,
            hazır kuponları, yarış sonuçları ve canlı yayınları ile Hipodrom.com daima 1 boy önde!
          </div>
          <a href="https://www.hipodrom.com/tjk-at-yarisi/tjk-bahis-yap" class="" target="_blank">
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="39" height="31" fill="none" viewBox="0 0 39 31">
                <path fill="#000"
                      d="m23.163.163-3.685 3.685 8.947 8.947-28.318.098L.09 18.09l28.599-.099L19.68 27l3.66 3.66L38.5 15.5 23.163.163Z"/>
              </svg>
            </i>
          </a>
        </div>
      </div>
    </section>
    <section class="w-full lg:max-w-full md:max-w-[552px] ">
      <div class="grid sm:justify-center lg:grid-cols-2 grid-cols-1 md:pt-[66px] pt-[40px] md:px-[84px] px-[40px] bg-white relative">
        <div class="flex flex-col gap-[26px]">
          <a href="https://indir.hipodrom.com/" target="_blank">
            <img src="/images/hipodrome-logo.svg" alt="">
          </a>
          <div class="text-black text-[32px] md:mt-0 mt-5 font-bold leading-[2rem]">At yarışı heyecanı <br>
            cebinde!
          </div>
          <div class="text-neutral-500 text-sm font-medium leading-5"><a href="https://hipodrom.com" class="font-bold" target="_blank">Hipodrom.com</a>'un mobil uygulamasını şimdi indir!
            <br>Uzman yorumları, detaylı istatistikleri, hazır kuponları, <br> yarış sonuçları ve canlı yayınları anında
            keşfet.
          </div>
          <div class="flex gap-2.5">
            <a href="https://apps.apple.com/tr/app/hipodrom-tjk-at-yar%C4%B1%C5%9F%C4%B1/id6472811853?l=tr" target="_blank"
                       class="max-w-[120px] h-[48px] p-3 bg-gray-200 rounded-full border border-zinc-300 justify-center items-center gap-1 inline-flex">
              <img width="24" height="24" src="/images/apple-fill.svg" alt="">
              <div class="text-black text-sm font-medium leading-tight">App Store</div>
          </a>
            <a href="https://play.google.com/store/apps/details?id=com.hipodrom.android" target="_blank"
                       class="max-w-[132px] h-[48px] p-3 bg-gray-200 rounded-full border border-zinc-300 justify-center items-center gap-1 inline-flex">
              <img width="24" height="24" src="/images/google-play-fill.svg" alt="">
              <div class="text-black text-sm font-medium leading-tight">Google Play</div>
          </a>
          </div>
        </div>
        <div class="flex items-center justify-center lg:mt-0 mt-[28px]">
          <img src="/images/hipodrome-mobile.png" alt="">
        </div>
      </div>
    </section>
    <FAQ />
  </div>
    </div>
  </div>
</template>